import { parseJsonObject } from 'utilities/common.util';
import formatDate from 'utilities/formatDate';

const mapUser = user => {
    const {
        first_name: firstName,
        id,
        last_name: lastName,
        profile_photo: profilePhoto
    } = user ?? {};

    return {
        firstName,
        id,
        lastName,
        profilePhoto
    };
};

const kebabCase = text => text.toLowerCase().replace(/\s/g, '-');

const normalizeDropdown = items =>
    items.map(item => {
        if (typeof item === 'string') {
            return {
                label: item,
                value: kebabCase(item)
            };
        }

        return item;
    });

const normalizeDropdowns = (items, selections) =>
    items.reduce((accumulator, item) => {
        const dropdownCopy = { ...item };

        dropdownCopy.options = normalizeDropdown(dropdownCopy.options);

        if (!('id' in dropdownCopy)) {
            dropdownCopy.id = kebabCase(dropdownCopy.title);
        }
        dropdownCopy.selectedIndex = selections[dropdownCopy.id]
        accumulator.push(dropdownCopy);

        return accumulator;
    }, []);

const resolveAttachments = activityAttachments =>
    activityAttachments.reduce((accumulator, attachments) => {
        const { deleted, file, id, name, uploaded_timestamp } = attachments;

        if (!deleted) {
            accumulator.push({
                deleted,
                file,
                id,
                name,
                uploaded_timestamp,
                url: file
            });
        }

        return accumulator;
    }, []);

const resolveComments = ({caseComments, querySchemas, schema}) =>
caseComments.reduce((accumulator, comments) => {
        const {
            created_at: createdAt,
            deleted,
            id,
            text: content,
            user_case_comments: userComments = []
        } = comments;
        
        if (userComments.length === 0) {
            return [];
        }

        const userId = userComments[0]?.tenant_user?.id;
        const tenantUser = userComments[0]?.tenant_user;
        var user;
        if(querySchemas && querySchemas.length > 0){
            for(const {value:s} of querySchemas){
                if(tenantUser[`user_${s}`]){
                    user = tenantUser[`user_${s}`]
                }
            }
            if(!user){
                user = tenantUser[`user_${schema}`];
            }
        }else{
            user = tenantUser[`user_${schema}`];
        }

        const { firstName, lastName } = mapUser(user);

        const authorName = `${firstName ?? ''} ${lastName ?? ''}`.trim();

        accumulator.push({
            content,
            createdAt: createdAt,
            deleted,
            id,
            user: {id: userId, name: authorName}
        });

        return accumulator;
    }, []);

const resolveEntities = caseEntities =>
    caseEntities.reduce((accumulator, entities) => {
        const { name } = entities.entity;

        accumulator.push(name);

        return accumulator;
    }, []);

const resolveForms = groupForms =>
    groupForms.reduce((accumulator, form) => {
        const formForProcessing = form ? form : {form_questions: [], id: null, name: null};
        const { form_questions: formQuestions, id: formId, name: formName } = formForProcessing;

        const questions = formQuestions.reduce(
            (accumulator, questions) => {
                const { id, required, question } = questions;

                const { formComponent, index, name, questionId, type, choices, settings } = question;

                const questionChoices = choices.reduce((accumulator, choice) => {
                    accumulator.push({
                        isChecked: false,
                        label: choice?.text,
                        name: name,
                        value: kebabCase(choice?.text)
                    })
                    return accumulator;
                }, []);

                accumulator.push({
                    choices: questionChoices,
                    formComponent,
                    id,
                    index,
                    name,
                    questionId,
                    required,
                    settings,
                    type
                });
                return accumulator;
            },
            []
        );

        if(formId){
            accumulator = {
                formId,
                formName,
                questions
            };
        }

        return accumulator;
    }, []);

const resolveAnswers = ({groupForms, schema}) =>
    groupForms.reduce((accumulator, form) => {
        const formForProcessing = form ? form : {form_questions: [], id: null, name: null};
        const {
            form_questions: formQuestions,
            id: formId,
            name: formName
        } = formForProcessing

        const questions = formQuestions.reduce(
            (accumulator, questions) => {
                const { id, required, question, case_form_answers: formAnswers } = questions;

                const { formComponent, index, name, questionId, type } = question;

                const answers = formAnswers.reduce(
                    (accumulator, answer) => {
                        const {
                            id: answerId,
                            user_id: userId,
                            question_id: questionId,
                            answer: questionAnswer,
                            tenant_user: tenantUser 
                        } = answer;

                        const user = tenantUser[`user_${schema}`];
                        const {first_name: firstName, last_name: lastName} = user;
                        const answerUser = {id: userId, name: `${firstName} ${lastName}`}
                        accumulator.push({answerUser, id:answerId, questionAnswer: parseJsonObject(questionAnswer), questionId, userId});
                        return accumulator;
                    }, []
                );

                answers.sort((a,b) => a.id - b.id);

                accumulator.push({
                    answers,
                    formComponent,
                    id,
                    index,
                    name,
                    questionId,
                    required,
                    type
                });
                return accumulator;
            },
            []
        );

        if(formId){
            accumulator = {
                formId,
                formName,
                questions
            };
        }

        return accumulator;
    }, []);

const resolveHistory = ({caseHistory, querySchemas, schema}) =>
caseHistory.reduce((accumulator, history) => {
        const { color, created_at: createdAt, message, tenant_user: tenantUser } = history;

        var user;
        if(querySchemas && querySchemas.length > 0){
            for(const {value:s} of querySchemas){
                if(tenantUser[`user_${s}`]){
                    user = tenantUser[`user_${s}`]
                }
            }
            if(!user){
                user = tenantUser[`user_${schema}`];
            }
        }else{
            user = tenantUser[`user_${schema}`];
        }
        const { firstName, lastName } = mapUser(user);

        const date = new Date(createdAt);
        const text = `${firstName ?? ''} ${lastName ?? ''} ${message}`.trim();

        accumulator.push({
            color,
            createdAt: date,
            date: formatDate(date),
            text
        });

        return accumulator;
    }, []);

const resolvePolicies = casePolicies => {
    return casePolicies?.reduce((accumulator, { document: policy, section }) => {
        if (section == null){
            accumulator.push({description: policy?.description, id: `${policy?.id}.0`, name: policy?.name, riskLevel: policy?.risk_label});
        }else{
            accumulator.push({description: section?.description, id: `${policy?.id}.${section?.id}`, name: section?.name, riskLevel: section?.riskLevel})
        }

        return accumulator;
    }, [])
};

const resolveUsers = ({caseUsers, querySchemas, schema}) =>
    caseUsers.reduce((accumulator, users) => {
            const { tenant_user: tenantUser } = users;
    
            const id = tenantUser.id;
            var user;
            if(querySchemas && querySchemas.length > 0){
                for(const {value:s} of querySchemas){
                    if(tenantUser[`user_${s}`]){
                        user = tenantUser[`user_${s}`]
                    }
                }
                if(!user){
                    user = tenantUser[`user_${schema}`];
                }
            }else{
                user = tenantUser[`user_${schema}`];
            }
    
            accumulator.push({
                firstName: user.first_name,
                id,
                lastName: user.last_name,
                type: 'user'
            });
    
            return accumulator;
        }, []);

/**
 * Note: Shape of case_policies - 
 * case_policies:
 * Array(1)
 * 0: {
 *      case_id: 51,
 *      document_mapping_id: 1,
 *      document_section_id: null,
 *      id: 1,
 *      __typename: "skematic_case_policies"
 * }
 */

const normalizeData = ({ data, querySchemas, schema }) =>
    data && data.length > 0 && data.reduce(
        (accumulator, cases) => {
            const {
                activity: linkedActivity,
                action_plan: actionPlan,
                activity: caseActivity,
                case_attachments: caseAttachments,
                case_name: caseName,
                case_policies: casePolicies,
                caseTypeByCaseType: caseType,
                closed,
                tenant_user: caseCreatedBy,
                created_date: caseCreatedDate,
                deleted,
                dropdown_selections: dropdownSelections,
                due_date: caseDueDate,
                id: caseId,
                // document: oldPolicies,
                // section: caseSections,
                creation_reason: caseCreationReason,
                originated: caseOrigination,
                proposed_solution: proposedSolution,
                resolution,
                case_comments: caseComments,
                case_histories: caseHistory,
                case_entities: caseEntities,
                linkages: caseLinkages,
                linkagesByCaseId2,
                user_cases: caseUsers,
                case_user_involveds: caseUsersInvolved,
                risk_label: caseRiskLabel
            } = cases;

            const attachments = resolveAttachments(caseAttachments);
            const entities = resolveEntities(caseEntities);
            const form = [caseType?.form].filter((option) => (option));
            const comments = resolveComments({caseComments, querySchemas, schema});
            const policies = resolvePolicies(casePolicies);
            const users = resolveUsers({caseUsers, querySchemas, schema});
            const usersInvolved = caseUsersInvolved.length > 0 ? resolveUsers({caseUsers:caseUsersInvolved, querySchemas, schema}): [];

            const startDate = new Date(caseCreatedDate);
            const dueDate = new Date(caseDueDate);
            
            const history = resolveHistory({caseHistory, querySchemas, schema});

            var createdBy;
            if(querySchemas && querySchemas.length > 0){
                for(const {value:s} of querySchemas){
                    if(caseCreatedBy[`user_${s}`]){
                        createdBy = {
                            firstName: caseCreatedBy[`user_${s}`].first_name,
                            id: caseCreatedBy[`user_${s}`].id,
                            lastName: caseCreatedBy[`user_${s}`].last_name,
                            type: 'user'
                        }
                    }
                }
                if(!createdBy){
                    createdBy = {
                        firstName: caseCreatedBy[`user_${schema}`].first_name,
                        id: caseCreatedBy[`user_${schema}`].id,
                        lastName: caseCreatedBy[`user_${schema}`].last_name,
                        type: 'user'
                    }
                }
            }else{
                createdBy = {
                    firstName: caseCreatedBy[`user_${schema}`].first_name,
                    id: caseCreatedBy[`user_${schema}`].id,
                    lastName: caseCreatedBy[`user_${schema}`].last_name,
                    type: 'user'
                }
            }

            // const policyReference = {}
            // if (caseSections !== null){
            //     policyReference.description = caseSections?.description || 'No associated policies';
            //     policyReference.id = caseSections?.id || 0;
            //     policyReference.name = caseSections?.name || 'No associated policies';
            //     policyReference.riskLevel = caseSections?.riskLevel || 'neutral';
            // }else{
            //     policyReference.description = oldPolicies?.description || 'No associated policies';
            //     policyReference.id = oldPolicies?.id || 0;
            //     policyReference.name = oldPolicies?.name || 'No associated policies';
            //     policyReference.riskLevel = oldPolicies?.risk_label || 'neutral';
            // }

            const caseLinksOne = caseLinkages ? caseLinkages.reduce((accumulator, caseLink) => {
                const newLink = {
                    id: caseLink?.activity?.id,
                    name: caseLink?.activity?.name
                }
                if(newLink?.id){
                    accumulator?.activityLinks.push(newLink);
                }
                const newCaseLink = {
                    id: caseLink?.caseByCaseId2?.id,
                    name: caseLink?.caseByCaseId2?.case_name
                }
                if(newCaseLink?.id){
                    accumulator?.caseLinks.push(newCaseLink);
                }
                if(caseLink?.log){
                    accumulator?.logLinks.push({id:caseLink?.log?.id, name: caseLink?.log?.log_types[0]?.log_type?.name});
                }
                return accumulator;
            }, {activityLinks: [], caseLinks: [], logLinks: []}) : {activityLinks: [], caseLinks: [], logLinks: []};
            const caseLinksTwo = linkagesByCaseId2 ? linkagesByCaseId2.reduce((accumulator, caseLink) => {
                const newLink = {
                    id: caseLink?.activity?.id,
                    name: caseLink?.activity?.name
                }
                if(newLink?.id){
                    accumulator?.activityLinks.push(newLink);
                }
                const newCaseLink = {
                    id: caseLink?.case?.id,
                    name: caseLink?.case?.case_name
                }
                if(newCaseLink?.id){
                    accumulator?.caseLinks.push(newCaseLink);
                }
                if(caseLink?.log){
                    accumulator?.logLinks.push({id:caseLink?.log?.id, name: caseLink?.log?.log_types[0]?.log_type?.name});
                }
                return accumulator;
            }, {activityLinks: [], caseLinks: [], logLinks: []}) : {activityLinks: [], caseLinks: [], logLinks: []};
            const activityLinks = [...caseLinksOne?.activityLinks, ...caseLinksTwo?.activityLinks];
            const caseLinks = [...caseLinksOne?.caseLinks, ...caseLinksTwo?.caseLinks];
            const logLinks = [...caseLinksOne?.logLinks, ...caseLinksTwo?.logLinks];

            const forms =
                form.length > 0
                    ? resolveForms(form)
                    : {};         

            const answers = form.length > 0 ? resolveAnswers({groupForms:form, schema}) : {};

            const allAnswers =  form.length > 0 ? form.reduce((accumulator, forms) => {
                const additionalQuestions = resolveAnswers({groupForms:[forms], schema});
                accumulator.questions.push(...additionalQuestions?.questions);
                return accumulator
            }, {questions:[]}) : {};

            accumulator = {
                actionPlan,
                activityCase: {
                    activity: {
                        id: caseActivity?.id || 0,
                        riskLevel: caseActivity?.risk_label || 'neutral',
                        title: caseActivity?.name || '-'
                    },
                    creationReason: caseCreationReason,
                    policyReference: policies,
                    type: caseType.name
                },
                activityLinkages: activityLinks,
                allAnswers,
                answers,
                attachments,      
                caseLinkages: caseLinks,
                caseName,
                comments: comments.sort((objA, objB) => (objA.createdAt < objB.createdAt) ? 1 : -1),
                createdBy,
                closed,
                deleted,
                dropdownSelections: dropdownSelections,
                dropdowns: (caseType?.dropdown) ? normalizeDropdowns(caseType.dropdown, dropdownSelections) : [],
                dueDate: formatDate(dueDate),
                entities,
                forms,
                history,
                id: caseId,
                linkedActivity,
                logLinkages: logLinks,
                originated: caseOrigination,
                proposedSolution,
                resolution,
                riskLabel: caseRiskLabel,
                schema,
                startDate: formatDate(startDate),
                type: caseType,
                users: users,
                usersInvolved
            };
            return accumulator;
        },
        {}
        );
    
    export default normalizeData;