import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const querySpecCase = (tenantSchema, caseId, userGQL) => gql`
    query getCase {
        ${tenantSchema}_case(where: {id: {_eq: ${caseId}}}) {
          id
          action_plan
          activity {
            id
            name
            risk_label
          }
          activity_id
          case_name
          deleted
          form_id
          case_policies {
            id
            case_id
            document {
              description
              id
              name
              risk_label
            }
            document_mapping_id
            document_section_id
            section {
              description
              id
              name
              riskLevel
            }
          }
          caseTypeByCaseType {
            dropdown
            name
            id
          }
          case_attachments {
              deleted
              name
              file
              id,
              uploaded_timestamp
          }
          created_date
          closed
          dropdown_selections
          due_date
          risk_color
          risk_label
          linkages {
            activity {
              id
              name
            }
            caseByCaseId2 {
              id
              case_name
            }
            log{
              id
                log_types {
                  log_type {
                    name
                  }
                }
            }
          }
          linkagesByCaseId2{
            activity {
              id
              name
            }
            case {
              id
              case_name
            }
            log{
              id
                log_types {
                  log_type {
                    name
                  }
                }
            }
          }
          creation_reason
          originated
          proposed_solution
          resolution
          case_comments {
            created_at
            deleted
            id
            text
            user_case_comments {
              tenant_user {
                id
                ${userGQL}
              }
            }
          }
          case_histories {
            color
            created_at
            id
            message
            tenant_user {
              ${userGQL}
            }
          }
          case_user_involveds {
            tenant_user {
              id
              ${userGQL}
            }
          }
          user_cases {
            tenant_user {
              id
              ${userGQL}
            }
          }
          document {
            description
            id
            name
            risk_label
          }
          section {
              description
              id
              name
              riskLevel
          }
          case_entities {
            entity {
              name
            }
          }
          tenant_user {
            id
            ${userGQL}
          }
        }
      }
`;

const querySpecForm = (tenantSchema, formId, caseId, userGQL) => gql`
    query getForm {
        ${tenantSchema}_form(where: {id: {_eq: ${formId}}}) {
            id
            name
            form_questions {
                id
                required
                question {
                    formComponent
                    id
                    index
                    name
                    questionId
                    type
                    choices(order_by: {text: asc}) {
                      text
                    }
                    settings
                }
                case_form_answers(where: {case_id: {_eq: ${caseId}}}, order_by: {id: desc}) {
                    id
                    user_id
                    question_id
                    answer
                    tenant_user{
                      ${userGQL}
                    }
                }
            }
        }
    }
`;

const useData = (caseId, caseSchema) => {
  const { useQuerySchemas, useSchema } = useGlobalStateHooks();

  const [querySchemas] = useQuerySchemas();
  const [schema] = useSchema();
  var userGQL = '';
  if(querySchemas.length > 0){
    for(const s of querySchemas){
        if(s.value){
            userGQL += `user_${s.value} {
              first_name
              last_name
              id
              profile_photo
            }`
        }
    }
  }else{
    if(schema){
        userGQL += `user_${schema} {
          first_name
          last_name
          id
          profile_photo
        }`
    }
  }

  const { data: caseData, loading: caseLoading, error: caseError, refetch: refetchCase } = useQuery(querySpecCase(caseSchema, caseId, userGQL), { fetchPolicy: 'no-cache' });
  const formId = caseData?.[`${caseSchema}_case`]?.[0]?.form_id;

  const { data: formData, loading: formLoading, error: formError, refetch: refetchForm } = useQuery(querySpecForm(caseSchema, formId, caseId, userGQL), {
    fetchPolicy: 'no-cache',
    skip: !formId,
  });

  async function refetch() {
    const promises = [refetchCase()];
    if (formId) promises.push(refetchForm());
    
    await Promise.all(promises);
  }

  const combinedData = useMemo(() => {
    if (caseData) {
      if(formData){
        return caseData[`${caseSchema}_case`].map(caseItem => {
          const formDetails = formData[`${caseSchema}_form`].find(form => form.id === caseItem.form_id);
          return {
            ...caseItem,
            caseTypeByCaseType: {
              ...caseItem.caseTypeByCaseType,
              form: formDetails || {}
            }
          };
        });
      } else {
        return caseData[`${caseSchema}_case`];
      }
    }
    return [];
  }, [caseData, caseSchema, formData]);

  return {data: combinedData, loading: caseLoading || formLoading , error: caseError || formError, refetch };

};

export default useData;
