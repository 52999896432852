import { useCallback, useEffect, useState } from 'react';

import useVariableForm from 'hooks/useVariableForm';
import SectionWithBorder from 'components/SectionWithBorder';
import Title from 'components/Title';
import SaveButton from 'components/SaveButton';

import useTranslation from './hooks/useTranslation';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import styles from './styles.module.scss';
import { isObject } from 'utilities/common.util';
import useData from './hooks/useData';
import ActivityFormComponent from 'components/ActivityFormComponent';
import { useFormCache } from 'hooks/use-form-cache.hook';
import { useFormComponents } from 'hooks/use-form-components.hook';
import { addCaseQuestionsStorgeId } from '../../constants';
import { toast } from 'react-toastify';

const AddCaseQuestions = props => {
    const {
        caseId,
        className = '',
        formId,
        formsRef,
        inactive = false,
        // eslint-disable-next-line no-unused-vars
        onRefetchCase,
        questions: presetQuestions
    } = props;

    const {
        // NEXT,
        QUESTIONS
        // VIEW_FULL_FORM
    } = useTranslation();

    const { registerFormComponent } = useFormComponents();

    const formCache = useFormCache({
        entityId: caseId,
        formId,
        storageId: addCaseQuestionsStorgeId
    });

    const [questions, setQuestions] = useState(() =>
        initQuestions(presetQuestions)
    );

    const [_errorMessage, setErrorMessage] = useState();
    const [_createdFormSubmission, createFormSubmission] = useData();
    const { useUserId } = useGlobalStateHooks();
    const [isSaving, setIsSaving] = useState(false);

    const [userId] = useUserId();

    const form = useVariableForm();

    const { handleForm } = form;
    const { handleError, handleSubmit, handleValid, handleValidPreBulk } = form;

    useEffect(() => {
        formsRef?.addFormRef(formId, {
            submit: handleSave
        });
    });

    // const navigate = useNavigate();

    function saveQuestions(data = []) {
        saveToStorage(data);
        setQuestions(data);
    }

    function saveToStorage(data = []) {
        const answers = data.reduce((acc, question) => {
            acc[question.id] = question.answer;
            return acc;
        }, {});
        formCache.saveQuestionsToStorage(answers);
    }

    function onChangeRefComponent({ id }) {
        return data => {
            const newQuestions = questions.map(question => {
                if (question.id === id) {
                    return {
                        ...question,
                        answer: data
                    };
                }
                return question;
            });
            saveQuestions(newQuestions);
        };
    }

    function initQuestions(defaultQuestions) {
        const storageQuestions = formCache.loadQuestionsFromStorage();
        return defaultQuestions.map(question => {
            return {
                ...question,
                answer: storageQuestions?.[question.id] || question.answer
            };
        });
    }

    const handleSave = useCallback(async () => {
        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);
            setIsSaving(false);
            return;
        }
        const formObject = {};
        const newAnswers = [];

        for (const question of questions) {
            const originalQuestion = presetQuestions.find(
                q => q.id === question.id
            );

            const newAnswer = JSON.stringify(question?.answer);

            if (
                originalQuestion &&
                JSON.stringify(originalQuestion?.answer) !== newAnswer
            ) {
                newAnswers.push({
                    answer: isObject(question?.answer)
                        ? newAnswer
                        : question?.answer,
                    questionId: question?.id
                });
            }
        }

        formObject['answers'] = newAnswers;
        formObject['caseId'] = caseId;
        formObject['formId'] = formId;
        formObject['userId'] = parseInt(userId);

        try {
            setIsSaving(true);
            await createFormSubmission(formObject);
            await onRefetchCase();
            toast.success('Form saved successfully!');
            return formObject;
        } catch (error) {
            console.error(error, 'error');
            toast.error('Failed to save form.');
        } finally {
            setIsSaving(false);
        }
        //eslint-disable-next-line
    }, [
        handleForm,
        setErrorMessage,
        createFormSubmission,
        caseId,
        formId,
        userId,
        questions
    ]);

    // const handleClose = useCallback(() => {
    //     setCloseAfterSubmit(true);
    //     const formObject = handleSave();
    //     setActivityStatus({
    //         id: caseId,
    //         status: 'complete'
    //     });
    // }, [caseId, handleSave, setActivityStatus, setCloseAfterSubmit]);

    useEffect(() => {
        const filteredQuestions = questions.filter(
            question => question?.type === 'checkBoxInput'
        );
        for (const question of filteredQuestions) {
            const answerArray = question?.answer
                ? question?.answer.split(',').map(x => parseInt(x))
                : [];
            const answerDict = answerArray.reduce(
                (a, v) => ({ ...a, [`${question?.id}_${v}`]: true }),
                {}
            );
            handleValidPreBulk(answerDict);
        }
        //eslint-disable-next-line
    }, [questions]);

    return (
        <SectionWithBorder
            className={`${className} ${styles.addActivityQuestions}`}
        >
            <Title className={styles.title} text={QUESTIONS} />

            {questions.map(
                ({
                    answer,
                    choices,
                    formComponent,
                    id,
                    index,
                    questionId,
                    name,
                    settings,
                    type
                }) => {
                    if (formComponent === 'newForm') {
                        return (
                            <ActivityFormComponent
                                answer={answer}
                                choices={choices}
                                formComponent={formComponent}
                                handleError={handleError}
                                handleForm={handleForm}
                                handleSubmit={handleSubmit}
                                handleValid={handleValid}
                                id={id}
                                inactive={inactive}
                                index={index}
                                name={name}
                                onChangeRefComponent={onChangeRefComponent}
                                questionId={questionId}
                                questions={questions}
                                registerFormComponent={registerFormComponent}
                                settings={settings}
                                setQuestions={saveQuestions}
                                type={type}
                            />
                        );
                    } else {
                        return <></>;
                    }
                }
            )}

            {/* <FullFormDrawer
                handleError={handleError}
                handleSave={handleSave}
                handleSaveClose={handleClose}
                handleSubmit={handleSubmit}
                handleValid={handleValid}
                questions={questions}
            >
                {({ toggle }) => (
                    <InfoHollowButton
                        className={styles.infoHollowButton}
                        onClick={toggle}
                        text={VIEW_FULL_FORM}
                    />
                )}
            </FullFormDrawer> */}

            {!inactive && (
                <SaveButton
                    className={styles.saveButton}
                    isDisabled={isSaving}
                    isLoading={isSaving}
                    onClick={handleSave}
                    saveText="Save"
                />
            )}

            {/* <SaveButton
                className={styles.saveAndCloseButton} 
                onClick={handleClose}
                saveText={"Save and Complete"}
            /> */}
        </SectionWithBorder>
    );
};

export default AddCaseQuestions;
